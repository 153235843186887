import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';

export const Container = styled.div`
  padding: 4rem 4rem;
  max-width: 1000px;
  margin: 5rem auto 15rem;
  line-height: 1.5;
  box-shadow: 0px 3px 10px #c5c5c5;

  @media (max-width: ${breakpoints.DESKTOP}px) {
    padding: 2rem 2rem;
    line-height: 1.2;
    box-shadow: none;
    margin: 0 auto 15rem;
  }
  h1,
  h2,
  h3,
  h4 h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  .logo {
    max-width: 450px;
    margin: auto;
    margin-bottom: 16px;
  }

  .katex-html {
    height: 100%;
    overflow: visible;

    @media (max-width: ${breakpoints.DESKTOP}px) {
      font-size: 80%;
    }

    @media (max-width: ${breakpoints.TABLET}px) {
      font-size: 70%;
      padding: 5px;
      overflow: scroll;
    }

    @media (max-width: ${breakpoints.PHONE}px) {
      font-size: 70%;
    }
  }

  p > img {
    max-width: 100%;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 2em;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
  }

  h2 {
    font-size: 1.5em;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
  }

  h3 {
    font-size: 1.25em;
  }

  p {
    margin-top: 0;
    margin-bottom: 16px;
  }

  pre {
    padding: 16px;
    overflow-x: auto;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
    color: inherit;
    margin-top: 0;
    margin-bottom: 16px;
    border: none;

    code {
      display: inline;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: initial;
      border: 0;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 2em;
    margin-bottom: 16px;
  }
  ul {
    list-style: disc;
    padding-left: 2em;
    margin-bottom: 16px;
  }

  li {
    padding: 4px;
  }

  .gatsby-resp-image-background-image {
    background-image: none;
  }
`;
